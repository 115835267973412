<template>
  <header class="header" :class="{ 'scrolled': scrolled }">
    <div class="container">
      <nav class="navbar">
        <router-link to="/" class="nav-logo">PA</router-link>
        
        <div class="nav-menu-button" @click="toggleMenu">
          <div class="bar" :class="{ 'active': menuActive }"></div>
          <div class="bar" :class="{ 'active': menuActive }"></div>
          <div class="bar" :class="{ 'active': menuActive }"></div>
        </div>
        
        <ul class="nav-menu" :class="{ 'active': menuActive }">
          <li class="nav-item" v-for="(link, index) in navLinks" :key="index">
            <router-link 
              :to="link.path" 
              class="nav-link" 
              @click="closeMenu"
              :class="{ 'active': $route.path === link.path }"
            >
              {{ link.name }}
            </router-link>
          </li>
        </ul>
      </nav>
    </div>
  </header>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue'

const menuActive = ref(false)
const scrolled = ref(false)
const navLinks = [
  { name: 'Home', path: '/' },
  { name: 'About', path: '/about' },
//  { name: 'Projects', path: '/projects' },
  { name: 'Articles', path: '/articles' },
  { name: 'Contact', path: '/contact'}
]

const toggleMenu = () => {
  menuActive.value = !menuActive.value
  document.body.style.overflow = menuActive.value ? 'hidden' : ''
}

const closeMenu = () => {
  if (menuActive.value) {
    menuActive.value = false
    document.body.style.overflow = ''
  }
}

const handleScroll = () => {
  scrolled.value = window.scrollY > 50
}

onMounted(() => {
  window.addEventListener('scroll', handleScroll)
})

onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll)
})
</script>

<script>
export default {
  name: 'NavBar'
}
</script>

<style scoped>
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  transition: all 0.3s ease;
  padding: 20px 0;
  background-color: transparent;
}

.header.scrolled {
  background-color: white;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
  padding: 15px 0;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-logo {
  font-size: 1.8rem;
  font-weight: 700;
  color: var(--primary-color);
  text-decoration: none;
}

.nav-menu {
  display: flex;
  gap: 30px;
}

.nav-link {
  color: var(--text-color);
  font-weight: 500;
  position: relative;
  padding: 5px 0;
  transition: all 0.3s ease;
}

.nav-link:hover, 
.nav-link.active {
  color: var(--primary-color);
}

.nav-link::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  background-color: var(--primary-color);
  transition: width 0.3s ease;
}

.nav-link:hover::after,
.nav-link.active::after {
  width: 100%;
}

.nav-menu-button {
  display: none;
  flex-direction: column;
  cursor: pointer;
  gap: 6px;
  padding: 5px;
}

.bar {
  width: 25px;
  height: 3px;
  background-color: var(--text-color);
  transition: all 0.3s ease;
}

.bar.active:nth-child(1) {
  transform: translateY(9px) rotate(45deg);
}

.bar.active:nth-child(2) {
  opacity: 0;
}

.bar.active:nth-child(3) {
  transform: translateY(-9px) rotate(-45deg);
}

@media (max-width: 768px) {
  .nav-menu-button {
    display: flex;
    z-index: 1001;
  }
  
  .nav-menu {
    position: fixed;
    top: 0;
    right: -100%;
    width: 100%;
    height: 100vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    transition: right 0.3s ease;
    z-index: 1000;
    gap: 40px;
  }
  
  .nav-menu.active {
    right: 0;
  }
  
  .nav-link {
    font-size: 1.3rem;
  }
}
</style>
