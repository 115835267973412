<template>
  <div class="about-page">
    <!-- Header Section -->
    <section class="section about-header">
      <div class="container">
        <h1 class="section-title">About Me</h1>
        <p class="about-subtitle">
          Learn more about my background, skills, and experience.
        </p>
      </div>
    </section>

    <!-- Main About Section -->
    <section class="section about-main">
      <div class="container">
        <div class="about-grid">
          <div class="about-image">
            <transition name="fade">
              <img :src="'/images/' + currentImage" :key="currentImage" alt="Profile Image" class="about-image-cycle">
            </transition>
          </div>

          <div class="about-content">
            <h2 class="about-title">Paul Agustin</h2>

            <p class="about-text">
            Hello! I’m Paul, a passionate Solutions Engineer with a strong background in performance management, product storytelling, and customer success. With over 20 years of experience in tech—spanning training, support, customer success, and presales—I specialize in helping organizations bring clarity, alignment, and momentum to their people strategies through technology.
            </p>
            <p class="about-text">
              My journey into this world began with a deep interest in how systems and people interact. Early in my career, I discovered how powerful the right tools can be when paired with thoughtful implementation and a strong understanding of user needs. Since then, I’ve been focused on translating complex business goals into actionable solutions that drive engagement, growth, and results.
            </p>
            <p class="about-text">
              I thrive at the intersection of technology and human impact. Whether I’m building realistic demo environments, guiding strategic conversations with HR leaders, or collaborating with product teams to improve user experience, my goal is always to create meaningful connections between people and performance.
            </p>
            <p class="about-text">
              I believe that the best enterprise tools don’t just work—they empower. My approach centers on empathy, clarity, and curiosity, ensuring every solution is tailored, scalable, and easy to love.
              </p>

            <div class="about-details">
              <div class="detail-item">
                <span class="detail-label">Name:</span>
                <span class="detail-value">Paul Agustin</span>
              </div>
              <div class="detail-item">
                <span class="detail-label">Email:</span>
                <span class="detail-value">hello@paulagustin.com</span>
              </div>
              <div class="detail-item">
                <span class="detail-label">Location:</span>
                <span class="detail-value">New York, NY, USA</span>
              </div>
              <!--
              <div class="detail-item">
                <span class="detail-label">Availability:</span>
                <span class="detail-value available">Available for Consult</span>
              </div>
              -->
            </div>

            <div class="about-actions">
              <a href="#" class="btn" @click.prevent="downloadResume">
                <i class="fas fa-download"></i> Download Resume
              </a>
              <router-link to="/contact" class="btn btn-outline">Get In Touch</router-link>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Skills Section 
    <section class="section skills-section">
      <div class="container">
        <h2 class="section-title">My Skills</h2>

        <div class="skills-categories">
          <div class="skills-category">
            <h3 class="category-title">
              <i class="fas fa-code"></i> Front-end Development
            </h3>
            <div class="skills-list">
              <div class="skill-item" v-for="(skill, index) in frontendSkills" :key="'fe-'+index">
                <span class="skill-name">{{ skill.name }}</span>
                <div class="skill-bar">
                  <div class="skill-progress" :style="{ width: skill.level + '%' }"></div>
                </div>
                <span class="skill-percentage">{{ skill.level }}%</span>
              </div>
            </div>
          </div>

          <div class="skills-category">
            <h3 class="category-title">
              <i class="fas fa-server"></i> Back-end Development
            </h3>
            <div class="skills-list">
              <div class="skill-item" v-for="(skill, index) in backendSkills" :key="'be-'+index">
                <span class="skill-name">{{ skill.name }}</span>
                <div class="skill-bar">
                  <div class="skill-progress" :style="{ width: skill.level + '%' }"></div>
                </div>
                <span class="skill-percentage">{{ skill.level }}%</span>
              </div>
            </div>
          </div>

          <div class="skills-category">
            <h3 class="category-title">
              <i class="fas fa-paint-brush"></i> Design
            </h3>
            <div class="skills-list">
              <div class="skill-item" v-for="(skill, index) in designSkills" :key="'ds-'+index">
                <span class="skill-name">{{ skill.name }}</span>
                <div class="skill-bar">
                  <div class="skill-progress" :style="{ width: skill.level + '%' }"></div>
                </div>
                <span class="skill-percentage">{{ skill.level }}%</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    -->

    <!-- Education & Experience Section -->
    <section class="section timeline-section">
      <div class="container">
        <h2 class="section-title">Education & Experience</h2>

        <div class="timeline-tabs">
          <div class="tab-header">
            <button 
              class="tab-btn" 
              :class="{ active: activeTab === 'experience' }"
              @click="activeTab = 'experience'"
            >
              <i class="fas fa-briefcase"></i> Work Experience
            </button>
            <button 
              class="tab-btn" 
              :class="{ active: activeTab === 'education' }"
              @click="activeTab = 'education'"
            >
              <i class="fas fa-graduation-cap"></i> Education
            </button>
          </div>

          <div class="tab-content">
            <div class="timeline" v-if="activeTab === 'experience'">
              <div class="timeline-item" v-for="(item, index) in experience" :key="'exp-'+index">
                <div class="timeline-marker">
                  <i class="fas fa-briefcase"></i>
                </div>
                <div class="timeline-content">
                  <div class="timeline-date">{{ item.period }}</div>
                  <h3 class="timeline-title">{{ item.title }}</h3>
                  <h4 class="timeline-company">{{ item.company }}</h4>
                  <p class="timeline-description">{{ item.description }}</p>
                </div>
              </div>
            </div>

            <div class="timeline" v-if="activeTab === 'education'">
              <div class="timeline-item" v-for="(item, index) in education" :key="'edu-'+index">
                <div class="timeline-marker">
                  <i class="fas fa-graduation-cap"></i>
                </div>
                <div class="timeline-content">
                  <div class="timeline-date">{{ item.period }}</div>
                  <h3 class="timeline-title">{{ item.degree }}</h3>
                  <h4 class="timeline-company">{{ item.institution }}</h4>
                  <p class="timeline-description">{{ item.description }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Contact CTA Section -->
    <section class="section contact-cta-section">
      <div class="container">
        <div class="cta-content">
          <h2 class="cta-title">Let's Work Together</h2>
          <p class="cta-description">
            Interested in working together? Let's discuss your project!
          </p>
          <router-link to="/contact" class="btn">Get In Touch</router-link>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup>
import { ref } from 'vue'

const activeTab = ref('experience')
/*  
const frontendSkills = [
  { name: 'HTML5 & CSS3', level: 95 },
  { name: 'JavaScript', level: 90 },
  { name: 'Vue.js', level: 85 },
  { name: 'React', level: 80 },
  { name: 'TypeScript', level: 75 }
]

const backendSkills = [
  { name: 'Node.js', level: 85 },
  { name: 'Express', level: 80 },
  { name: 'MongoDB', level: 75 },
  { name: 'PostgreSQL', level: 70 },
  { name: 'PHP', level: 65 }
]

const designSkills = [
  { name: 'UI/UX Design', level: 90 },
  { name: 'Figma', level: 85 },
  { name: 'Adobe XD', level: 80 },
  { name: 'Photoshop', level: 70 },
  { name: 'Illustrator', level: 65 }
]
*/

const experience = [
  {
    period: '2015 - Present',
    title: 'Senior Director, Solutions Engineering',
    company: 'Betterworks, New York, NY',
    description: 'Held various roles. Currently, leading a global team of Solutions Engineers, partnering with sales, product, and marketing to craft compelling demos and drive business growth. Focused on connecting enterprise customers with tailored performance enablement solutions.'
  },
  {
    period: '2013 - 2015',
    title: 'Customer Success Manager',
    company: 'Microsoft (via Yammer), New York, NY',
    description: 'Partnered with enterprise clients to drive adoption of Yammer as a social collaboration platform within Office 365. Delivered onboarding programs, led executive workshops, and advised on internal communication strategies.'
  },
  {
    period: '2006 - 2013',
    title: 'Training Specialist',
    company: '3M Health Information Systems, Silver Spring, MD',
    description: 'Designed and delivered product training for hospital systems nationwide. Created engaging web-based learning materials and led on-site workshops for medical professionals and technical staff.'
  }
]

const education = [
  {
    period: '1999 - 2003',
    degree: 'Bachelor of Science in Computer Science',
    institution: 'The Johns Hopkins University, Baltimore',
    description: 'Studied software development, systems architecture, and human-computer interaction. Built a strong technical foundation that informs my work in tech enablement, performance platforms, and customer strategy.'
  }
]

const downloadResume = () => {
  // In a real application, this would trigger a download of a PDF file
  alert('Resume download functionality would be implemented here')
}

const currentImage = ref('lego.png')
</script>

<script>
export default {
  name: 'AboutView'
}
</script>

<style scoped>
/* About Page Styles */
.about-page {
  padding-top: 80px;
}

.about-header {
  background-color: var(--primary-color);
  color: white;
  padding: 100px 0 50px;
  text-align: center;
}

.about-header .section-title {
  color: white;
}

.about-header .section-title::after {
  background-color: white;
}

.about-subtitle {
  font-size: 1.2rem;
  max-width: 600px;
  margin: 0 auto;
  opacity: 0.9;
  color: white;
}

/* Main About Section */
.about-main {
  background-color: white;
}

.about-grid {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 50px;
  align-items: start;
}

.about-image .about-image-cycle {
  width: 100%;
  aspect-ratio: 1;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: var(--card-shadow);
  object-fit: cover;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.about-title {
  font-size: 2.5rem;
  margin-bottom: 10px;
  color: var(--primary-color);
}

.about-subtitle {
  font-size: 1.3rem;
  color: var(--light-text-color);
  margin-bottom: 25px;
}

.about-text {
  margin-bottom: 20px;
  line-height: 1.8;
}

.about-details {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin: 30px 0;
}

.detail-item {
  display: flex;
  align-items: center;
}

.detail-label {
  font-weight: 600;
  min-width: 90px;
}

.detail-value {
  color: var(--light-text-color);
}

.detail-value.available {
  color: #27ae60;
}

.about-actions {
  display: flex;
  gap: 15px;
  margin-top: 20px;
}

/* Skills Section */
.skills-section {
  background-color: var(--background-color);
}

.skills-categories {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
}

.skills-category {
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: var(--card-shadow);
}

.category-title {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 25px;
  font-size: 1.3rem;
  color: var(--primary-color);
}

.skills-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.skill-item {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.skill-name {
  font-weight: 500;
}

.skill-bar {
  height: 8px;
  background-color: #e9ecef;
  border-radius: 4px;
  overflow: hidden;
}

.skill-progress {
  height: 100%;
  background-color: var(--primary-color);
  border-radius: 4px;
  transition: width 1s ease;
}

.skill-percentage {
  align-self: flex-end;
  font-size: 0.9rem;
  color: var(--light-text-color);
}

/* Timeline Section */
.timeline-section {
  background-color: white;
}

.timeline-tabs {
  margin-top: 50px;
}

.tab-header {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}

.tab-btn {
  padding: 12px 25px;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid #e9ecef;
  font-size: 1.1rem;
  font-weight: 500;
  color: var(--light-text-color);
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 8px;
}

.tab-btn.active {
  color: var(--primary-color);
  border-bottom-color: var(--primary-color);
}

.tab-btn i {
  font-size: 1.2rem;
}

.timeline {
  position: relative;
  padding-left: 30px;
}

.timeline::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 7px;
  width: 2px;
  background-color: #e9ecef;
}

.timeline-item {
  position: relative;
  margin-bottom: 50px;
}

.timeline-item:last-child {
  margin-bottom: 0;
}

.timeline-marker {
  position: absolute;
  top: 0;
  left: -30px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: white;
  border: 2px solid var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--primary-color);
  font-size: 0.8rem;
}

.timeline-date {
  display: inline-block;
  padding: 5px 10px;
  background-color: rgba(52, 152, 219, 0.1);
  color: var(--primary-color);
  border-radius: 4px;
  font-size: 0.9rem;
  font-weight: 500;
  margin-bottom: 10px;
}

.timeline-title {
  font-size: 1.3rem;
  margin-bottom: 5px;
}

.timeline-company {
  font-size: 1rem;
  color: var(--light-text-color);
  margin-bottom: 15px;
  font-weight: normal;
}

.timeline-description {
  line-height: 1.6;
}

/* Contact CTA Section */
.contact-cta-section {
  background-color: var(--primary-color);
  color: white;
  padding: 100px 0;
  text-align: center;
}

.cta-title {
  font-size: 2.8rem;
  margin-bottom: 20px;
}

.cta-description {
  font-size: 1.3rem;
  margin-bottom: 40px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  opacity: 0.9;
}

.contact-cta-section .btn {
  background-color: white;
  color: var(--primary-color);
  border-color: white;
  font-size: 1.1rem;
  padding: 15px 30px;
}

.contact-cta-section .btn:hover {
  background-color: transparent;
  color: white;
}

/* Media Queries */
@media (max-width: 992px) {
  .about-grid {
    grid-template-columns: 1fr;
    gap: 30px;
  }

  .about-image {
    max-width: 300px;
    margin: 0 auto;
  }

  .about-details {
    grid-template-columns: 1fr;
  }

  .cta-title {
    font-size: 2.3rem;
  }
}

@media (max-width: 768px) {
  .about-title {
    font-size: 2rem;
  }

  .about-actions {
    flex-direction: column;
  }

  .tab-header {
    flex-direction: column;
    gap: 15px;
  }

  .tab-btn {
    width: 100%;
    justify-content: center;
  }

  .cta-title {
    font-size: 2rem;
  }

  .cta-description {
    font-size: 1.1rem;
  }
}
</style>