<template>
  <footer class="footer">
    <div class="container">
      <div class="footer-content">
        <div class="footer-about">
          <h3 class="footer-title">Paul Agustin</h3>
          <p class="footer-description">
            A passionate pre-sales engineer focused on creating beautiful and functional demo experiences.
          </p>
          <div class="footer-social">
            <a href="https://github.com/CardsOnHudson" target="_blank" class="social-icon">
              <i class="fa-brands fa-github"></i>
            </a>
            <a href="https://www.linkedin.com/in/pagustin/" target="_blank" class="social-icon">
              <i class="fa-brands fa-linkedin"></i>
            </a>
            <a href="https://bsky.app/profile/paulagustin.com" target="_blank" class="social-icon">
              <i class="fa-brands fa-bluesky" style="color: #ffffff;"></i>
            </a>
          </div>
        </div>
        
        <div class="footer-links">
          <h3 class="footer-title">Quick Links</h3>
          <ul class="footer-nav">
            <li v-for="(link, index) in navLinks" :key="index">
              <router-link :to="link.path">{{ link.name }}</router-link>
            </li>
          </ul>
        </div>
        
        <div class="footer-contact">
          <h3 class="footer-title">Contact Me</h3>
          <ul class="contact-list">
            <li>
              <i class="fas fa-envelope"></i>
              <span>hello@paulagustin.com</span>
            </li>
            <li>
              <i class="fas fa-map-marker-alt"></i>
              <span>New York, NY USA</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="footer-bottom">
        <p class="copyright">
          &copy; {{ currentYear }} Paul Agustin. All Rights Reserved.
        </p>
        <!-- Uncomment this section to add footer bottom links -->
        <!--
        <div class="footer-bottom-links">
          <a href="#">Privacy Policy</a>
          <a href="#">Terms of Service</a>
        </div>
        -->
      </div>

    </div>
  </footer>
</template>

<script setup>
const navLinks = [
  { name: 'Home', path: '/' }
]
const currentYear = new Date().getFullYear()
</script>

<script>
export default {
  name: 'FooterComponent'
}
</script>

<style scoped>
.footer {
  background-color: var(--secondary-color);
  color: white;
  padding: 80px 0 30px;
}

.footer-content {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  gap: 50px;
  margin-bottom: 50px;
}

.footer-title {
  font-size: 1.3rem;
  margin-bottom: 20px;
  position: relative;
  display: inline-block;
}

.footer-title::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -8px;
  width: 40px;
  height: 2px;
  background-color: var(--primary-color);
}

.footer-description {
  margin-bottom: 20px;
  opacity: 0.8;
  line-height: 1.6;
}

.footer-social {
  display: flex;
  gap: 15px;
}

.social-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  transition: all 0.3s ease;
}

.social-icon:hover {
  background-color: var(--primary-color);
}

.footer-nav {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.footer-nav a {
  opacity: 0.8;
  transition: all 0.3s ease;
}

.footer-nav a:hover {
  opacity: 1;
  color: var(--primary-color);
  padding-left: 5px;
}

.contact-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.contact-list li {
  display: flex;
  align-items: center;
  gap: 12px;
}

.contact-list i {
  color: var(--primary-color);
}

.contact-list span {
  opacity: 0.8;
}

.footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.copyright {
  opacity: 0.7;
  font-size: 0.9rem;
}

.footer-bottom-links {
  display: flex;
  gap: 20px;
}

.footer-bottom-links a {
  font-size: 0.9rem;
  opacity: 0.7;
  transition: all 0.3s ease;
}

.footer-bottom-links a:hover {
  opacity: 1;
  color: var(--primary-color);
}

@media (max-width: 992px) {
  .footer-content {
    grid-template-columns: 1fr 1fr;
  }
  
  .footer-about {
    grid-column: span 2;
    margin-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .footer-content {
    grid-template-columns: 1fr;
    gap: 40px;
  }
  
  .footer-about {
    grid-column: auto;
  }
  
  .footer-bottom {
    flex-direction: column;
    gap: 15px;
    text-align: center;
  }
}
</style>
