<template>
  <div class="articles-page">
    <!-- Header Section -->
    <section class="section articles-header">
      <div class="container">
        <h1 class="section-title">My Articles</h1>
        <p class="articles-subtitle">
          A collection of articles I've written about performance management, people analytics, and technology.
        </p>
      </div>
    </section>

    <!-- Articles Grid Section -->
    <section class="section articles-grid-section">
      <div class="container">
        <div class="articles-grid">
          <div 
            v-for="(article, index) in articles" 
            :key="index"
            class="article-card"
            :class="{ 'featured': article.featured }"
          >
            <div class="article-image">
              <div class="image-placeholder">
                <i :class="article.icon"></i>
              </div>
            </div>

            <div class="article-info">
              <div class="article-meta">
                <span v-if="article.featured" class="article-badge">Featured</span>
                <span class="article-date">{{ article.date }}</span>
              </div>
              <h3 class="article-title">{{ article.title }}</h3>
              <p class="article-summary">{{ article.summary }}</p>
              <a :href="article.url" target="_blank" class="read-more">
                Read Article <i class="fas fa-arrow-right"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Newsletter Section 
    <section class="section newsletter-section">
      <div class="container">
        <div class="newsletter-content">
          <div class="newsletter-text">
            <h2 class="newsletter-title">Stay updated with my latest articles</h2>
            <p class="newsletter-description">
              Subscribe to receive notifications when I publish new content.
            </p>
          </div>
          <form class="newsletter-form" @submit.prevent="subscribeNewsletter">
            <input 
              type="email" 
              v-model="email" 
              placeholder="Enter your email" 
              required
              class="newsletter-input"
            >
            <button type="submit" class="btn newsletter-btn">Subscribe</button>
          </form>
        </div>
      </div>
    </section>
    -->
  </div>
</template>

<script setup>
//import { ref } from 'vue'
//const email = ref('')
const articles = [
  {
    title: 'Making the Business Case for HR Transformation',
    summary: 'Learn how to build a compelling, data-driven business case for HR transformation that aligns with your organization’s strategic goals.',
    date: 'September 11, 2024',
    icon: 'fas fa-list-check',
    featured: false,
    url: 'https://www.betterworks.com/magazine/making-the-business-case-for-hr-transformation/'
  },
  {
    title: '9 People Analytics Capabilities You Need for Data-Driven HR',
    summary: 'Explore nine essential people analytics capabilities that empower HR to make data-driven decisions, enhancing culture, retention, engagement, and overall organizational performance.',
    date: 'November 29, 2023',
    icon: 'fas fa-chart-column',
    featured: false,
    url: 'https://www.betterworks.com/magazine/people-analytics-capabilities-for-data-driven-hr/'
  },
  {
    title: 'How to Help Managers Become Better Managers When You Don’t Have Training Resources',
    summary: 'Discover practical ways to help managers grow through real-time feedback, goal alignment, and transparency—without relying on formal training programs.',
    date: 'April 20, 2022',
    icon: 'fas fa-person-chalkboard',
    featured: false,
    url: 'https://www.betterworks.com/magazine/how-to-help-managers-become-better-managers-when-you-dont-have-training-resources/'
  }
]
/*
const subscribeNewsletter = () => {
  // This would normally send the email to a backend for processing
  alert(`Thank you for subscribing with ${email.value}!`)
  email.value = ''
}*/
</script>

<script>
export default {
  name: 'ArticlesView'
}
</script>

<style scoped>
/* Articles Page Styles */
.articles-page {
  padding-top: 80px;
}

.articles-header {
  background-color: var(--primary-color);
  color: white;
  padding: 100px 0 50px;
  text-align: center;
}

.articles-header .section-title {
  color: white;
}

.articles-header .section-title::after {
  background-color: white;
}

.articles-subtitle {
  font-size: 1.2rem;
  max-width: 600px;
  margin: 0 auto;
  opacity: 0.9;
  color: white;
}

/* Articles Grid Section */
.articles-grid-section {
  padding-top: 60px;
}

.articles-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 30px;
}

.article-card {
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: var(--card-shadow);
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.article-card.featured {
  grid-column: span 2;
}

.article-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}

.article-image {
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.article-image .image-placeholder {
  width: 100%;
  height: 100%;
  background-color: rgba(52, 152, 219, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 4rem;
  color: var(--primary-color);
  transition: all 0.3s ease;
}

.article-card:hover .image-placeholder {
  transform: scale(1.05);
}

.article-info {
  padding: 25px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.article-meta {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.article-badge {
  display: inline-block;
  padding: 2px 8px;
  background-color: rgba(231, 76, 60, 0.1);
  color: var(--accent-color);
  border-radius: 4px;
  font-size: 0.8rem;
  font-weight: 500;
}

.article-date {
  color: var(--light-text-color);
  font-size: 0.9rem;
}

.article-title {
  margin-bottom: 10px;
  font-size: 1.3rem;
  color: var(--text-color);
}

.article-summary {
  color: var(--light-text-color);
  margin-bottom: 20px;
  line-height: 1.6;
  flex-grow: 1;
}

.read-more {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  color: var(--primary-color);
  font-weight: 500;
  transition: all 0.3s ease;
  margin-top: auto;
}

.read-more i {
  transition: transform 0.3s ease;
}

.read-more:hover {
  color: var(--accent-color);
}

.read-more:hover i {
  transform: translateX(5px);
}

/* Newsletter Section */
.newsletter-section {
  margin-top: 60px;
  background-color: var(--background-color);
}

.newsletter-content {
  background-color: white;
  padding: 60px;
  border-radius: 8px;
  box-shadow: var(--card-shadow);
  text-align: center;
}

.newsletter-title {
  font-size: 2rem;
  margin-bottom: 15px;
  color: var(--primary-color);
}

.newsletter-description {
  font-size: 1.1rem;
  color: var(--light-text-color);
  max-width: 600px;
  margin: 0 auto 30px;
}

.newsletter-form {
  display: flex;
  max-width: 500px;
  margin: 0 auto;
  gap: 10px;
  flex-wrap: wrap;
}

.newsletter-input {
  flex: 1;
  min-width: 250px;
  padding: 12px 20px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  font-size: 1rem;
}

.newsletter-btn {
  padding: 12px 25px;
}

/* Media Queries */
@media (max-width: 992px) {
  .article-card.featured {
    grid-column: span 1;
  }

  .newsletter-content {
    padding: 40px;
  }

  .newsletter-title {
    font-size: 1.8rem;
  }
}

@media (max-width: 768px) {
  .articles-grid {
    grid-template-columns: 1fr;
  }

  .newsletter-content {
    padding: 30px;
  }

  .newsletter-title {
    font-size: 1.6rem;
  }

  .newsletter-description {
    font-size: 1rem;
  }

  .newsletter-form {
    flex-direction: column;
  }

  .newsletter-input {
    width: 100%;
  }
}
</style>