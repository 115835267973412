<template>
  <div class="projects-page">
    <!-- Header Section -->
    <section class="section projects-header">
      <div class="container">
        <h1 class="section-title">My Projects</h1>
        <p class="projects-subtitle">
          A collection of my recent work and personal projects.
        </p>
      </div>
    </section>
    
    <!-- Filter Section -->
    <section class="section filter-section">
      <div class="container">
        <div class="filter-container">
          <div class="filter-label">Filter by:</div>
          <div class="filter-buttons">
            <button 
              class="filter-btn" 
              :class="{ active: activeFilter === 'all' }"
              @click="setFilter('all')"
            >
              All
            </button>
            <button 
              v-for="(category, index) in categories" 
              :key="index"
              class="filter-btn" 
              :class="{ active: activeFilter === category }"
              @click="setFilter(category)"
            >
              {{ category }}
            </button>
          </div>
        </div>
      </div>
    </section>
    
    <!-- Projects Grid Section -->
    <section class="section projects-grid-section">
      <div class="container">
        <div class="projects-grid">
          <div 
            v-for="(project, index) in filteredProjects" 
            :key="index"
            class="project-card"
            :class="{ 'featured': project.featured }"
          >
            <div class="project-image">
              <div class="image-placeholder">
                <i :class="project.icon"></i>
                <span>{{ project.title }}</span>
              </div>
              <div class="project-overlay">
                <div class="project-links">
                  <a :href="project.demo" target="_blank" class="project-link">
                    <i class="fas fa-external-link-alt"></i>
                  </a>
                  <a :href="project.github" target="_blank" class="project-link">
                    <i class="fab fa-github"></i>
                  </a>
                </div>
              </div>
            </div>
            
            <div class="project-info">
              <div class="project-meta">
                <span v-if="project.featured" class="project-badge">Featured</span>
                <span class="project-category">{{ project.category }}</span>
              </div>
              <h3 class="project-title">{{ project.title }}</h3>
              <p class="project-description">{{ project.description }}</p>
              <div class="project-tags">
                <span v-for="(tag, tagIndex) in project.tags" :key="tagIndex" class="tag">{{ tag }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    
    <!-- Interested in Collaboration Section -->
    <section class="section collaboration-section">
      <div class="container">
        <div class="collaboration-content">
          <div class="collaboration-text">
            <h2 class="collaboration-title">Interested in collaboration?</h2>
            <p class="collaboration-description">
              I'm always open to discussing product design work or partnership opportunities.
            </p>
          </div>
          <router-link to="/contact" class="btn">Start a conversation</router-link>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'

const activeFilter = ref('all')
const categories = ['Web Development', 'UI/UX Design', 'Mobile App']
const projects = [
  {
    title: 'E-commerce Platform',
    description: 'A full-featured online store with shopping cart, user authentication, and payment processing.',
    category: 'Web Development',
    icon: 'fas fa-shopping-cart',
    tags: ['Vue.js', 'Node.js', 'MongoDB'],
    featured: true,
    demo: 'https://example.com/ecommerce',
    github: 'https://github.com/johndoe/ecommerce'
  },
  {
    title: 'Analytics Dashboard',
    description: 'A comprehensive analytics platform featuring real-time data visualization and reporting.',
    category: 'Web Development',
    icon: 'fas fa-chart-bar',
    tags: ['React', 'D3.js', 'Express'],
    featured: true,
    demo: 'https://example.com/analytics',
    github: 'https://github.com/johndoe/analytics'
  },
  {
    title: 'Fitness Tracker App',
    description: 'Mobile application for tracking workouts, progress, and nutrition with personalized recommendations.',
    category: 'Mobile App',
    icon: 'fas fa-dumbbell',
    tags: ['React Native', 'Firebase', 'Redux'],
    featured: false,
    demo: 'https://example.com/fitness',
    github: 'https://github.com/johndoe/fitness'
  },
  {
    title: 'Travel Blog',
    description: 'A responsive blog website with content management system for sharing travel experiences.',
    category: 'Web Development',
    icon: 'fas fa-plane',
    tags: ['WordPress', 'PHP', 'MySQL'],
    featured: false,
    demo: 'https://example.com/travelblog',
    github: 'https://github.com/johndoe/travelblog'
  },
  {
    title: 'Restaurant Website Redesign',
    description: 'Complete UI/UX redesign for a high-end restaurant with online reservation system.',
    category: 'UI/UX Design',
    icon: 'fas fa-utensils',
    tags: ['Figma', 'HTML/CSS', 'JavaScript'],
    featured: false,
    demo: 'https://example.com/restaurant',
    github: 'https://github.com/johndoe/restaurant'
  },
  {
    title: 'Weather App',
    description: 'A sleek weather application with location-based forecasts and interactive radar maps.',
    category: 'Web Development',
    icon: 'fas fa-cloud-sun',
    tags: ['Vue.js', 'API Integration', 'Geolocation'],
    featured: false,
    demo: 'https://example.com/weather',
    github: 'https://github.com/johndoe/weather'
  },
  {
    title: 'Task Management App',
    description: 'Productivity application for organizing tasks, setting priorities, and tracking progress.',
    category: 'Mobile App',
    icon: 'fas fa-tasks',
    tags: ['Flutter', 'Firebase', 'State Management'],
    featured: false,
    demo: 'https://example.com/taskmanager',
    github: 'https://github.com/johndoe/taskmanager'
  },
  {
    title: 'Portfolio Design System',
    description: 'A comprehensive design system and component library for portfolio websites.',
    category: 'UI/UX Design',
    icon: 'fas fa-palette',
    tags: ['Design Systems', 'Component Library', 'Storybook'],
    featured: false,
    demo: 'https://example.com/designsystem',
    github: 'https://github.com/johndoe/designsystem'
  }
]

const filteredProjects = computed(() => {
  if (activeFilter.value === 'all') {
    return projects
  } else {
    return projects.filter(project => project.category === activeFilter.value)
  }
})

const setFilter = (filter) => {
  activeFilter.value = filter
}
</script>

<script>
export default {
  name: 'ProjectsView'
}
</script>

<style scoped>
/* Projects Page Styles */
.projects-page {
  padding-top: 80px;
}

.projects-header {
  background-color: var(--primary-color);
  color: white;
  padding: 100px 0 50px;
  text-align: center;
}

.projects-header .section-title {
  color: white;
}

.projects-header .section-title::after {
  background-color: white;
}

.projects-subtitle {
  font-size: 1.2rem;
  max-width: 600px;
  margin: 0 auto;
  opacity: 0.9;
}

/* Filter Section */
.filter-section {
  padding-top: 50px;
  padding-bottom: 0;
}

.filter-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.filter-label {
  font-weight: 500;
  color: var(--light-text-color);
}

.filter-buttons {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
}

.filter-btn {
  padding: 8px 16px;
  background-color: transparent;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.filter-btn.active {
  background-color: var(--primary-color);
  color: white;
  border-color: var(--primary-color);
}

.filter-btn:hover:not(.active) {
  background-color: rgba(52, 152, 219, 0.1);
  border-color: var(--primary-color);
  color: var(--primary-color);
}

/* Projects Grid Section */
.projects-grid-section {
  padding-top: 50px;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 30px;
}

.project-card {
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: var(--card-shadow);
  transition: all 0.3s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.project-card.featured {
  grid-column: span 2;
  grid-row: span 1;
}

.project-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}

.project-image {
  position: relative;
  width: 100%;
  aspect-ratio: 16/9;
  overflow: hidden;
}

.project-image .image-placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(52, 152, 219, 0.1);
  color: var(--primary-color);
  font-size: 3rem;
  transition: all 0.3s ease;
}

.project-card:hover .image-placeholder {
  transform: scale(1.05);
}

.project-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(44, 62, 80, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: all 0.3s ease;
}

.project-card:hover .project-overlay {
  opacity: 1;
}

.project-links {
  display: flex;
  gap: 20px;
}

.project-link {
  width: 50px;
  height: 50px;
  background-color: white;
  color: var(--primary-color);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
  transition: all 0.3s ease;
  transform: translateY(20px);
  opacity: 0;
}

.project-card:hover .project-link {
  transform: translateY(0);
  opacity: 1;
}

.project-card:hover .project-link:nth-child(1) {
  transition-delay: 0.1s;
}

.project-card:hover .project-link:nth-child(2) {
  transition-delay: 0.2s;
}

.project-link:hover {
  background-color: var(--primary-color);
  color: white;
}

.project-info {
  padding: 25px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.project-meta {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.project-badge {
  display: inline-block;
  padding: 2px 8px;
  background-color: rgba(231, 76, 60, 0.1);
  color: var(--accent-color);
  border-radius: 4px;
  font-size: 0.8rem;
  font-weight: 500;
}

.project-category {
  color: var(--light-text-color);
  font-size: 0.9rem;
}

.project-title {
  margin-bottom: 10px;
  font-size: 1.3rem;
}

.project-description {
  color: var(--light-text-color);
  margin-bottom: 20px;
  line-height: 1.6;
  flex-grow: 1;
}

.project-tags {
  margin-top: auto;
}

/* Collaboration Section */
.collaboration-section {
  background-color: var(--background-color);
}

.collaboration-content {
  background-color: white;
  padding: 60px;
  border-radius: 8px;
  box-shadow: var(--card-shadow);
  text-align: center;
}

.collaboration-title {
  font-size: 2rem;
  margin-bottom: 15px;
  color: var(--primary-color);
}

.collaboration-description {
  font-size: 1.1rem;
  color: var(--light-text-color);
  max-width: 600px;
  margin: 0 auto 30px;
}

/* Media Queries */
@media (max-width: 992px) {
  .project-card.featured {
    grid-column: span 1;
  }
  
  .collaboration-content {
    padding: 40px;
  }
  
  .collaboration-title {
    font-size: 1.8rem;
  }
}

@media (max-width: 768px) {
  .filter-container {
    flex-direction: column;
    gap: 15px;
  }
  
  .collaboration-content {
    padding: 30px;
  }
  
  .collaboration-title {
    font-size: 1.6rem;
  }
  
  .collaboration-description {
    font-size: 1rem;
  }
}
</style>
