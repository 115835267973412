<template>
  <div class="home-page">
    <!-- Hero Section -->
    <section class="hero">
      <div class="container">
        <div class="hero-content">
          <h1 class="hero-title">
            Hi, I'm <span class="highlight">Paul Agustin</span> <br>
            Storyteller
          </h1>
          <p class="hero-description">
            I design and build beautiful demos.
          </p>
          <!--
          <div class="hero-buttons">
            <router-link to="/projects" class="btn">View My Work</router-link>
            <router-link to="/contact" class="btn btn-outline">Contact Me</router-link>
          </div>
          -->
          <!--
          <div class="hero-scroll-indicator">
            <a href="#about" class="scroll-down">
              <span>Scroll Down</span>
              <i class="fas fa-chevron-down"></i>
            </a>
          </div>
          -->
        </div>
        <div class="hero-image">
          <img :src="require(`@/assets/images/${heroImage}`)" alt="Profile Image" class="hero-image-cycle">
        </div>
      </div>
    </section>

    <!-- About Section (Brief) -->
    <section id="about" class="section about-section">
      <div class="container">
        <h2 class="section-title">About Me</h2>
        <div class="about-content">
          <div class="about-skills">
            <h3>My Story</h3>
            <p>
            Welcome! I’m a passionate HR Sales Engineer with a deep focus on enabling high-performing organizations through intuitive, impactful technology. With over 20 years of experience across customer success, technical support, and presales, I specialize in translating your product's goals into smart, scalable solutions.
            </p>
            <div class="about-cta">
              <router-link to="/about" class="btn">More About Me</router-link>
            </div>
          </div>
          <div class="about-skills">
            <h3>My Skills</h3>
            <div class="skills-container">
              <div class="skill-item">
                <span class="skill-name">Solutions Architecture</span>
                <div class="skill-bar">
                  <div class="skill-progress" style="width: 95%"></div>
                </div>
              </div>
              <div class="skill-item">
                <span class="skill-name">Presales Strategy</span>
                <div class="skill-bar">
                  <div class="skill-progress" style="width: 90%"></div>
                </div>
              </div>
              <div class="skill-item">
                <span class="skill-name">People Enablement</span>
                <div class="skill-bar">
                  <div class="skill-progress" style="width: 85%"></div>
                </div>
              </div>
              <div class="skill-item">
                <span class="skill-name">HR Tech Expertise</span>
                <div class="skill-bar">
                  <div class="skill-progress" style="width: 80%"></div>
                </div>
              </div>
              <div class="skill-item">
                <span class="skill-name">Client Partnership & Success</span>
                <div class="skill-bar">
                  <div class="skill-progress" style="width: 75%"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Services Section -->
    <section class="section services-section">
      <div class="container">
        <h2 class="section-title">What I Do</h2>
        <div class="services-grid">
          <div class="service-card">
            <div class="service-icon">
              <i class="fas fa-code"></i>
            </div>
            <h3 class="service-title">Solution Design & Storytelling</h3>
            <p class="service-description">
              I craft tailored demo environments and narratives that clearly show how technology solves real-world business challenges.
            </p>
          </div>

          <div class="service-card">
            <div class="service-icon">
              <i class="fa-solid fa-comment"></i>
            </div>
            <h3 class="service-title">HR Tech Consulting</h3>
            <p class="service-description">
              I advise on best practices across performance, engagement, and recognition—helping HR leaders roll out tools with confidence and clarity.
            </p>
          </div>

          <div class="service-card">
            <div class="service-icon">
              <i class="fa-solid fa-handshake-simple"></i>
            </div>
            <h3 class="service-title">Presales Partnerships</h3>
            <p class="service-description">
              I work alongside sales teams and clients to uncover needs, navigate complexity, and co-create solutions that deliver lasting impact.
            </p>
          </div>

          <div class="service-card">
            <div class="service-icon">
              <i class="fa-solid fa-chart-simple"></i>
            </div>
            <h3 class="service-title">Performance Management Strategy</h3>
            <p class="service-description">
              I help organizations align teams and drive results through proven frameworks like OKRs, continuous feedback, and regular check-ins.
            </p>
          </div>
        </div>
      </div>
    </section>

    <!-- Featured Projects Section 
    <section class="section projects-section">
      <div class="container">
        <h2 class="section-title">Featured Projects</h2>
        <div class="projects-grid">
          <div class="project-card">
            <div class="project-image">
              <div class="image-placeholder">
                <i class="fas fa-laptop-code"></i>
                <span>Project 1</span>
              </div>
            </div>
            <div class="project-info">
              <h3 class="project-title">E-commerce Platform</h3>
              <p class="project-description">
                A full-featured online store with shopping cart, user authentication, and payment processing.
              </p>
              <div class="project-tags">
                <span class="tag">Vue.js</span>
                <span class="tag">Node.js</span>
                <span class="tag">MongoDB</span>
              </div>
              <div class="project-links">
                <a href="#" class="project-link">
                  <i class="fas fa-external-link-alt"></i> Live Demo
                </a>
                <a href="#" class="project-link">
                  <i class="fab fa-github"></i> Source Code
                </a>
              </div>
            </div>
          </div>

          <div class="project-card">
            <div class="project-image">
              <div class="image-placeholder">
                <i class="fas fa-chart-bar"></i>
                <span>Project 2</span>
              </div>
            </div>
            <div class="project-info">
              <h3 class="project-title">Analytics Dashboard</h3>
              <p class="project-description">
                A comprehensive analytics platform featuring real-time data visualization and reporting.
              </p>
              <div class="project-tags">
                <span class="tag">React</span>
                <span class="tag">D3.js</span>
                <span class="tag">Express</span>
              </div>
              <div class="project-links">
                <a href="#" class="project-link">
                  <i class="fas fa-external-link-alt"></i> Live Demo
                </a>
                <a href="#" class="project-link">
                  <i class="fab fa-github"></i> Source Code
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="projects-cta">
          <router-link to="/projects" class="btn">View All Projects</router-link>
        </div>
      </div>
    </section>
    -->
    <!-- Testimonials Section 
    <section class="section testimonials-section">
      <div class="container">
        <h2 class="section-title">What Clients Say</h2>
        <div class="testimonials-container">
          <div class="testimonial-card">
            <div class="testimonial-content">
              <i class="fas fa-quote-left quote-icon"></i>
              <p class="testimonial-text">
                John delivered an exceptional website that perfectly represents our brand. His attention to detail and responsiveness throughout the project was outstanding.
              </p>
            </div>
            <div class="testimonial-author">
              <div class="author-image">
                <i class="fas fa-user"></i>
              </div>
              <div class="author-info">
                <h4 class="author-name">Sarah Johnson</h4>
                <p class="author-position">CEO, TechStart Inc.</p>
              </div>
            </div>
          </div>

          <div class="testimonial-card">
            <div class="testimonial-content">
              <i class="fas fa-quote-left quote-icon"></i>
              <p class="testimonial-text">
                Working with John was a pleasure. He understood our requirements perfectly and delivered a website that exceeded our expectations in both functionality and design.
              </p>
            </div>
            <div class="testimonial-author">
              <div class="author-image">
                <i class="fas fa-user"></i>
              </div>
              <div class="author-info">
                <h4 class="author-name">Michael Brown</h4>
                <p class="author-position">Marketing Director, Innovate Co.</p>
              </div>
            </div>
          </div>

          <div class="testimonial-card">
            <div class="testimonial-content">
              <i class="fas fa-quote-left quote-icon"></i>
              <p class="testimonial-text">
                John's technical skills and eye for design are top-notch. He created a beautiful, responsive website that has significantly increased our online presence.
              </p>
            </div>
            <div class="testimonial-author">
              <div class="author-image">
                <i class="fas fa-user"></i>
              </div>
              <div class="author-info">
                <h4 class="author-name">Emily Davis</h4>
                <p class="author-position">Founder, Creative Studio</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    -->
    <!-- Contact CTA Section -->
    <section class="section contact-cta-section">
      <div class="container">
        <div class="cta-content">
          <h2 class="cta-title">Let's Work Together</h2>
          <p class="cta-description">
            Have a project in mind? Let's discuss how I can help bring your ideas to life!
          </p>
          <router-link to="/contact" class="btn">Get In Touch</router-link>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  data() {
    return {
      heroImage: 'lego.png',
    };
  },
};
</script>

<style scoped>
/* Hero Section */
.hero {
  height: 100vh;
  min-height: 600px;
  display: flex;
  align-items: center;
  padding-top: 80px;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.9)), 
              url('https://source.unsplash.com/random/1600x900/?coding') center/cover no-repeat;
}

.hero .container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
  align-items: center;
}

.hero-title {
  font-size: 3.5rem;
  line-height: 1.2;
  margin-bottom: 20px;
  animation: fadeIn 1s ease-out;
}

.hero-description {
  font-size: 1.3rem;
  color: var(--light-text-color);
  margin-bottom: 40px;
  animation: fadeIn 1s ease-out 0.2s forwards;
  opacity: 0;
}

.hero-buttons {
  display: flex;
  gap: 15px;
  margin-bottom: 50px;
  animation: fadeIn 1s ease-out 0.4s forwards;
  opacity: 0;
}

.hero-scroll-indicator {
  animation: fadeIn 1s ease-out 0.6s forwards;
  opacity: 0;
}

.scroll-down {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--light-text-color);
  font-size: 0.9rem;
  transition: all 0.3s ease;
}

.scroll-down i {
  animation: bounceDown 2s infinite;
}

.scroll-down:hover {
  color: var(--primary-color);
}

.hero-image {
  animation: fadeIn 1s ease-out 0.4s forwards;
  opacity: 0;
  width: 300px;
  height: 300px;
  margin: 0 auto;
  border-radius: 50%;
  overflow: hidden;
}

.hero-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-placeholder {
  width: 100%;
  aspect-ratio: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(52, 152, 219, 0.1);
  border-radius: 50%;
  font-size: 8rem;
  color: var(--primary-color);
  animation: float 6s ease-in-out infinite;
}

.image-placeholder span {
  margin-top: 15px;
  font-size: 1.2rem;
}

@keyframes bounceDown {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(5px);
  }
  60% {
    transform: translateY(3px);
  }
}

/* About Section */
.about-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  align-items: start;
  padding: 20px 0;
}

.about-text {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.about-text p {
  line-height: 1.8;
  margin: 0;
  font-size: 1.05rem;
}

.about-cta {
  margin-top: 30px;
}

.about-skills h3 {
  margin-bottom: 30px;
  font-size: 1.5rem;
  color: var(--primary-color);
}

.skills-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.skill-item {
  width: 100%;
}

.skill-name {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
}

.skill-bar {
  height: 8px;
  background-color: #e9ecef;
  border-radius: 4px;
  overflow: hidden;
}

.skill-progress {
  height: 100%;
  background-color: var(--primary-color);
  border-radius: 4px;
  transition: width 1s ease;
}

/* Services Section */
.services-section {
  background-color: white;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
}

.service-card {
  background-color: var(--background-color);
  padding: 40px 30px;
  border-radius: 8px;
  box-shadow: var(--card-shadow);
  text-align: center;
  transition: all 0.3s ease;
}

.service-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}

.service-icon {
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(52, 152, 219, 0.1);
  color: var(--primary-color);
  border-radius: 50%;
  font-size: 1.8rem;
  margin: 0 auto 25px;
}

.service-title {
  margin-bottom: 15px;
  font-size: 1.3rem;
}

.service-description {
  color: var(--light-text-color);
  line-height: 1.6;
}

/* Projects Section */
.projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  margin-bottom: 40px;
}

.project-card {
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: var(--card-shadow);
  transition: all 0.3s ease;
}

.project-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}

.project-image {
  width: 100%;
  aspect-ratio: 16/9;
}

.project-image .image-placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(52, 152, 219, 0.1);
  color: var(--primary-color);
  font-size: 3rem;
  border-radius: 0;
}

.project-info {
  padding: 25px;
}

.project-title {
  margin-bottom: 10px;
  font-size: 1.3rem;
}

.project-description {
  color: var(--light-text-color);
  margin-bottom: 15px;
  line-height: 1.6;
}

.project-tags {
  margin-bottom: 20px;
}

.project-links {
  display: flex;
  gap: 15px;
}

.project-link {
  font-size: 0.9rem;
  color: var(--primary-color);
  display: flex;
  align-items: center;
  gap: 5px;
  transition: all 0.3s ease;
}

.project-link:hover {
  color: #2980b9;
}

.projects-cta {
  text-align: center;
}

/* Testimonials Section */
.testimonials-section {
  background-color: white;
}

.testimonials-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
}

.testimonial-card {
  background-color: var(--background-color);
  padding: 30px;
  border-radius: 8px;
  box-shadow: var(--card-shadow);
}

.testimonial-content {
  margin-bottom: 25px;
  position: relative;
}

.quote-icon {
  font-size: 2rem;
  color: rgba(52, 152, 219, 0.2);
  position: absolute;
  top: -10px;
  left: -10px;
}

.testimonial-text {
  position: relative;
  z-index: 1;
  line-height: 1.8;
}

.testimonial-author {
  display: flex;
  align-items: center;
  gap: 15px;
}

.author-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgba(52, 152, 219, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--primary-color);
  font-size: 1.3rem;
}

.author-name {
  font-size: 1.1rem;
  margin-bottom: 3px;
}

.author-position {
  font-size: 0.9rem;
  color: var(--light-text-color);
}

/* Contact CTA Section */
.contact-cta-section {
  background-color: var(--primary-color);
  color: white;
  padding: 100px 0;
  text-align: center;
}

.cta-title {
  font-size: 2.8rem;
  margin-bottom: 20px;
}

.cta-description {
  font-size: 1.3rem;
  margin-bottom: 40px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  opacity: 0.9;
}

.contact-cta-section .btn {
  background-color: white;
  color: var(--primary-color);
  border-color: white;
  font-size: 1.1rem;
  padding: 15px 30px;
}

.contact-cta-section .btn:hover {
  background-color: transparent;
  color: white;
}

/* Media Queries */
@media (max-width: 992px) {
  .hero-title {
    font-size: 2.8rem;
  }

  .hero-description {
    font-size: 1.1rem;
  }

  .about-content,
  .hero .container {
    grid-template-columns: 1fr;
    gap: 40px;
  }

  .hero-content {
    order: 2;
    text-align: center;
  }

  .hero-image {
    order: 1;

  }

  .hero-buttons {
    justify-content: center;
  }

  .hero-scroll-indicator {
    display: flex;
    justify-content: center;
  }

  .about-skills {
    order: 1;
  }

  .about-text {
    order: 2;
  }

  .cta-title {
    font-size: 2.3rem;
  }
}

@media (max-width: 768px) {
  .hero-title {
    font-size: 2.3rem;
  }

  .hero-image {
    max-width: 70%;
  }

  .services-grid,
  .testimonials-container {
    grid-template-columns: 1fr;
  }

  .cta-title {
    font-size: 2rem;
  }

  .cta-description {
    font-size: 1.1rem;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>